import React from "react"
import {
  ContactBox,
  ContactUsEmailLink,
  ContactUsPhoneLink,
  Link
} from "@life-without-barriers/react-components"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { getGatsbyImage } from "@life-without-barriers/gatsby-common"

import { SilListingPartnerLogo } from "../../contentTypes"

export interface SilListingContactBoxProps {
  phoneNumber: string
  emailAddress: string
  lwbLogo: IGatsbyImageData
  partnerLogo?: SilListingPartnerLogo
}

const SILListingContactBox = ({
  phoneNumber,
  emailAddress,
  partnerLogo,
  lwbLogo
}: SilListingContactBoxProps) => (
  <ContactBox title="To find out more about this home:">
    <div className="pv3 pv2-l">
      <ContactUsPhoneLink phoneNumber={phoneNumber} />
      <ContactUsEmailLink text={emailAddress} link={`mailto:${emailAddress}`} />
      {partnerLogo && (
        <div>
          <h4 className="mt5 fw5 copy ttu color-lwb-theme-darker">
            Proudly partnered
          </h4>
          <div className="flex mt4">
            <div className="w-50 ba b-lwb-grey-x-light mr3 pa2">
              <GatsbyImage
                image={getGatsbyImage(lwbLogo)}
                alt="Life Without Barriers"
              />
            </div>
            <Link
              to={partnerLogo.imageLinkUrl}
              target="_blank"
              className="db w-50 ba b-lwb-grey-x-light ml3 pa2"
            >
              {partnerLogo && (
                <GatsbyImage
                  image={partnerLogo.image.gatsbyImageData}
                  alt={partnerLogo.title}
                />
              )}
            </Link>
          </div>
        </div>
      )}
    </div>
  </ContactBox>
)

export default SILListingContactBox
