import React from "react"
import classNames from "classnames"
import {
  Helmet,
  StandardButton,
  Theme,
  Banner,
  IconBed,
  IconBath,
  IconDisability,
  PdfLink,
  Row,
  Container,
  Box,
  Variables,
  Section,
  KeyCodes,
  FeaturedVideo,
  routesObject
} from "@life-without-barriers/react-components"
import { graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Site } from "@life-without-barriers/gatsby-common"
import {
  contentfulHelpers as Contentful,
  stripVideoIdFromYoutubeUrl,
  scroll
} from "@life-without-barriers/utilities"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import { SRLWrapper, useLightbox } from "simple-react-lightbox"

import { DisabilityForm } from "@life-without-barriers/shared-contact-form"
import SilListingContactBox from "../../components/disability/SilListingContactBox"
import { SilListing, RichTextHtml } from "../../contentTypes"
import Layout from "../../components/disability/Layout"
import {
  FeatureOverlay,
  Introduction,
  ThumbnailOverlay
} from "../../components/disability/ReusedComponents"

const { black } = Variables

const { disabilityTheme } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    currentPage: SilListing
    lwbLogo: IGatsbyImageData
    metaImage: IGatsbyImageData
    featureImage: {
      childImageSharp: IGatsbyImageData
    }
    galleryImages: {
      edges: [
        {
          node: {
            childImageSharp: IGatsbyImageData
          }
        }
      ]
    }
  }
}

const getHtmlOrNothing = (item?: RichTextHtml): string | undefined => {
  if (!item) {
    return
  }
  return item && item.childMarkdownRemark.html
}

interface InformationItemProps {
  title: string
  rawHtml: string
}

const InformationItem = ({ title, rawHtml }: InformationItemProps) => {
  return (
    <div className="w-100 w-90-l pb4">
      <h3 className="ts-display-4 fw8">{title}</h3>
      <div
        className="information-item"
        dangerouslySetInnerHTML={{ __html: rawHtml }}
      ></div>
    </div>
  )
}

interface FactorsToConsiderProps {
  richTextBody: {
    raw?: string | null
    references?: Contentful.ContentfulAsset[] | null
  }
  className?: string
}

const FactorsToConsider = ({
  richTextBody,
  className
}: FactorsToConsiderProps) => (
  <div className="w-100 w-90-l">
    <h3 className="ts-display-4 fw8">Factors to consider when applying</h3>
    <div className={className}>{renderRichText(richTextBody)}</div>
  </div>
)

interface PropertySummaryProps {
  title: string
  state: string
  bedrooms: number
  bathrooms: number
  accessible: string
  className?: string
}

const SuburbBox = styled.div`
  flex-basis: auto;
`
const PropertySummary = ({
  title,
  state,
  bedrooms,
  bathrooms,
  accessible,
  className
}: PropertySummaryProps) => (
  <div className={`${className || ""}`}>
    <SuburbBox className="pa2 bg-lwb-grey-xxx-light">
      <div className="x-small fw5 ttu tracked">
        {title}, {state}
      </div>
    </SuburbBox>
    <div className="flex flex-row justify-around-ns ml0 ml4-ns mt3 mt0-ns pa2">
      <div className="flex justify-between">
        <IconBed ariaHidden color={black} height="22" />
        <div aria-label="Bedrooms" className="pl2 pr3">
          {bedrooms}
        </div>
      </div>
      <div className="flex justify-between">
        <IconBath ariaHidden color={black} height="22" />
        <div aria-label="Bathrooms" className="pl2 pr3">
          {bathrooms}
        </div>
      </div>
      <div className="flex justify-between">
        <IconDisability ariaHidden color={black} height="22" />
        <div aria-label="Accessibility" className="pl2 pr3">
          {accessible}
        </div>
      </div>
    </div>
  </div>
)

const SilListingPage = ({
  location,
  data: {
    site: { siteMetadata },
    currentPage,
    lwbLogo,
    metaImage
  }
}: Props) => {
  const {
    title,
    state,
    featureImage,
    listingDescription,
    galleryImages,
    bedrooms,
    bathrooms,
    accessible,
    contactDetails,
    partnerLogo,
    pdfBrochure,
    silHouseVideo
  } = currentPage

  const { phoneNumber, emailAddress } = contactDetails

  const silHouseVideoId =
    silHouseVideo && stripVideoIdFromYoutubeUrl(silHouseVideo.url)

  const propertySummary = {
    title,
    state,
    bedrooms,
    bathrooms,
    accessible
  }

  const introductionHtml = getHtmlOrNothing(currentPage.introduction)
  const roomInformationHtml = getHtmlOrNothing(currentPage.roomInformation)
  const houseInformationHtml = getHtmlOrNothing(currentPage.houseInformation)
  const housematesHtml = getHtmlOrNothing(currentPage.housemates)
  const locationInformationHtml = getHtmlOrNothing(
    currentPage.locationInformation
  )
  const currentSupportHtml = getHtmlOrNothing(currentPage.currentSupportModel)
  const accessibilityDescriptionHtml = getHtmlOrNothing(
    currentPage.accessibilityDescription
  )
  const youtubeDescriptionHtml = getHtmlOrNothing(
    currentPage.silHouseVideo?.youtubeDescription
  )
  const factorsToConsiderRichText = currentPage.factorsToConsider ?? null

  const breadcrumbItems = [
    routesObject.disabilityHome,
    {
      to: "/disability/services",
      text: "Disability services",
      title: "Return navigation"
    },
    {
      to: "/disability/services/supported-independent-living",
      text: "Supported Independent Living",
      title: "Return navigation"
    },
    {
      to: `/disability/services/supported-independent-living/vacancies/state/${state.toLowerCase()}`,
      text: `${state} Vacancies`,
      title: "Return navigation"
    }
  ]

  const allImages =
    (galleryImages &&
      galleryImages.map(({ description, gatsbyImageData }) => ({
        description,
        gatsbyImageData
      }))) ||
    []

  allImages.unshift({
    description: featureImage.description,
    gatsbyImageData: featureImage.gatsbyImageData
  })

  const topThreeImages = allImages.slice(1, 4)

  const galleryData = allImages.map((allImagesData) => {
    return {
      src: allImagesData.gatsbyImageData.images.fallback?.src || "",
      width: allImagesData.gatsbyImageData.width,
      height: allImagesData.gatsbyImageData.height,
      caption: allImagesData.description || ""
    }
  })

  const galleryOptions = {
    caption: {
      showCaption: false,
      overlayColor: "rgba(0,0,0,0.8)"
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showThumbnailsButton: false
    }
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { openLightbox } = useLightbox()

  const handleKeyDown = (e: React.KeyboardEvent, id: number) => {
    if (e.keyCode !== KeyCodes.SPACE && e.keyCode !== KeyCodes.RETURN) {
      return false
    }
    openLightbox(id)
  }

  const metaDescription = listingDescription
    ? listingDescription
    : `Shared & Supported Independent Living Vacancy in ${title}`

  return (
    <div>
      <Helmet
        title={`SIL Vacancy: ${title} | ${siteMetadata.title}`}
        description={metaDescription}
        image={metaImage}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        themeColor={disabilityTheme.medium}
      />
      <Layout
        fixedCTAPhoneNumber="1800 610 699"
        fixedCTAContact={
          <DisabilityForm
            formTitle="Enquire now"
            formId="disability-sil-listing"
            formKind="disability-home-living"
          />
        }
      >
        <Banner
          title={title}
          subTitle="Shared & Supported Independent Living vacancy"
          centered
          breadcrumbs={breadcrumbItems}
          share={true}
        />
        <Container>
          <Row className="flex-wrap relative">
            <Box className="w-100">
              <div className="flex flex-wrap">
                <div
                  className="w-100 w-75-ns relative pointer"
                  role="button"
                  onClick={() => openLightbox(0)}
                  onKeyUp={(e) => handleKeyDown(e, 0)}
                  tabIndex={0}
                  aria-label="Open gallery"
                >
                  <GatsbyImage
                    image={allImages[0].gatsbyImageData}
                    alt={allImages[0].description}
                  />
                  <FeatureOverlay className="flex dn-ns white ph3 pv2">
                    1/{allImages.length}
                  </FeatureOverlay>
                </div>
                <div className="dn db-ns w-25 pl2-ns flex-ns flex-column-ns">
                  {topThreeImages.map((image, i: number) => (
                    <div
                      key={i}
                      className={classNames([
                        { "w-100 pointer relative": true },
                        { mb2: i <= 1 }
                      ])}
                      role="button"
                      onClick={() => openLightbox(i + 1)}
                      onKeyUp={(e) => handleKeyDown(e, i + 1)}
                      tabIndex={0}
                      aria-label="Open gallery"
                    >
                      <GatsbyImage
                        image={image.gatsbyImageData}
                        alt={image.description}
                      />
                      <ThumbnailOverlay
                        className={`white ts-display-2 ${
                          i === 2 && allImages.length >= 5 ? `flex` : `dn`
                        } `}
                      >
                        +{allImages.length - 4}
                      </ThumbnailOverlay>
                    </div>
                  ))}
                </div>
              </div>
              <SRLWrapper elements={galleryData} options={galleryOptions} />
              <PropertySummary className="flex-ns mt3" {...propertySummary} />
            </Box>
          </Row>
          <Row topMargin="standard">
            <Box className="layout-readable">
              {listingDescription && (
                <p className="mt0 mb3 color-lwb-theme-dark w-100 ts-display-4 fw8">
                  {listingDescription}
                </p>
              )}
              {introductionHtml && (
                <Introduction
                  className="w-100 mt0"
                  dangerouslySetInnerHTML={{ __html: introductionHtml }}
                ></Introduction>
              )}
              <div className="db dn-l center tc mt3">
                <StandardButton
                  className="w-100 center"
                  shade="xdark"
                  onClick={() =>
                    scroll.scrollToElement("disability-sil-listing")
                  }
                >
                  Enquire Now
                </StandardButton>
              </div>
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row className="flex-wrap">
              <Box className="w-100 w-seven-twelfths-l layout-gap">
                {roomInformationHtml && (
                  <InformationItem
                    title="Room information"
                    rawHtml={roomInformationHtml}
                  ></InformationItem>
                )}
                {houseInformationHtml && (
                  <InformationItem
                    title="House information"
                    rawHtml={houseInformationHtml}
                  ></InformationItem>
                )}
                {housematesHtml && (
                  <InformationItem
                    title="Housemates"
                    rawHtml={housematesHtml}
                  ></InformationItem>
                )}
                {locationInformationHtml && (
                  <InformationItem
                    title="Location information"
                    rawHtml={locationInformationHtml}
                  ></InformationItem>
                )}
                {accessibilityDescriptionHtml && (
                  <InformationItem
                    title="Accessibility"
                    rawHtml={accessibilityDescriptionHtml}
                  ></InformationItem>
                )}
                {currentSupportHtml && (
                  <InformationItem
                    title="Current support model"
                    rawHtml={currentSupportHtml}
                  ></InformationItem>
                )}
                {factorsToConsiderRichText && (
                  <FactorsToConsider
                    richTextBody={factorsToConsiderRichText}
                  ></FactorsToConsider>
                )}
                {pdfBrochure && (
                  <div className="w-100 w-90-l pt4">
                    <h3 className="ts-display-4 fw8">
                      Download a printable brochure
                    </h3>
                    <p className="pb3 pb4-l">
                      You can download an accessible PDF brochure for you or the
                      person you support.
                    </p>
                    <PdfLink
                      to={pdfBrochure.file.url}
                      title={`${title} - SIL vacancy brochure`}
                    />
                  </div>
                )}
              </Box>
              <Box className="w-100 w-third-l mt4 mt0-l">
                <DisabilityForm
                  formTitle="Enquire now"
                  formId="disability-sil-listing"
                  formKind="disability-home-living"
                />
                <div className="mt4">
                  <SilListingContactBox
                    emailAddress={emailAddress}
                    phoneNumber={phoneNumber}
                    partnerLogo={partnerLogo}
                    lwbLogo={lwbLogo}
                  />
                </div>
              </Box>
            </Row>
          </Container>
        </Section>
        {silHouseVideoId && silHouseVideo ? (
          <Container>
            <FeaturedVideo
              videoId={silHouseVideoId}
              title={silHouseVideo.title}
              description={
                youtubeDescriptionHtml
                  ? {
                      kind: "html",
                      value: youtubeDescriptionHtml
                    }
                  : undefined
              }
              videoPosition="left"
            />
          </Container>
        ) : (
          ""
        )}
      </Layout>
    </div>
  )
}

export const contactDetailsQuery = graphql`
  fragment ContactDetails on ContentfulSilListing {
    contactDetails {
      phoneNumber
      emailAddress
    }
  }
`

export const partnerLogoQuery = graphql`
  fragment PartnerLogo on ContentfulSilListing {
    partnerLogo {
      title
      imageLinkUrl
      image {
        ...SmallRemoteContentfulImageAsset
      }
    }
  }
`

export const query = graphql`
  query ($id: String) {
    currentPage: contentfulSilListing(id: { eq: $id }) {
      id
      slug
      title
      listingDescription
      state
      bedrooms
      bathrooms
      accessible
      introduction {
        childMarkdownRemark {
          html
        }
      }
      roomInformation {
        childMarkdownRemark {
          html
        }
      }
      houseInformation {
        childMarkdownRemark {
          html
        }
      }
      housemates {
        childMarkdownRemark {
          html
        }
      }
      locationInformation {
        childMarkdownRemark {
          html
        }
      }
      accessibilityDescription {
        childMarkdownRemark {
          html
        }
      }
      currentSupportModel {
        childMarkdownRemark {
          html
        }
      }
      factorsToConsider {
        raw
      }
      featureImage {
        ...ForcedAspectRatioRemoteImageAssetQuery
      }
      galleryImages {
        ...ForcedAspectRatioRemoteImageAssetQuery
      }
      pdfBrochure {
        ...RemoteContentfulAsset
      }
      silHouseVideo {
        url
        title
        youtubeDescription {
          childMarkdownRemark {
            html
          }
        }
      }
      ...ContactDetails
      ...PartnerLogo
    }
    lwbLogo: file(relativePath: { regex: "/lwb-logo-stacked.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 90)
      }
    }
    metaImage: file(relativePath: { regex: "/OG-image-sil.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 90)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default SilListingPage
